.contact-background {
  background: url('/assets/images/contact-background.jpg');
  background-size: cover;
  background-position: center;

  h2 {
    font-size: 72px;
    font-weight: 600;
  }

  h3 {
    font-weight: 600;
    color: #fff;
  }

  p {
    color: #000;
    font-size: 18px;
  }
}