body {
  background: #f3f3f3;
}

.sm-svg svg {
  height: 25px;
}

.xs-svg {
  margin-left: 5px
}
.xs-svg svg {
  height: 15px;
  margin-right: 2px;
}

.arrow-svg svg {
  margin-left: -7px;
  margin-top: -3px;
}

.z-99 {
  z-index: 99;
}

.z-2999 {
  z-index: 2999;
}

.dark-blue-construction-background {
  .col-md-4 {
    padding-left: 1px;
    padding-right: 1px;
    margin-bottom: 2px;
  }
}

.light-gray-border {
  display: block;
  border: 1px solid $light-gray;
  opacity: 0.2;
}

.background-primary {
  background: $brand-dark;

  a {
    font-size: 18px;
    text-decoration: underline;
  }
}