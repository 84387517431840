@font-face {
  font-family: "Saira Condensed";
  src: url("../fonts/saira_condensed/SairaCondensed-Regular.ttf")
    format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Saira Condensed";
  src: url("../fonts/saira_condensed/SairaCondensed-Bold.ttf")
    format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Saira Condensed";
  src: url("../fonts/saira_condensed/SairaCondensed-ExtraBold.ttf")
    format("truetype");
  font-display: swap;
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Overpass";
  src: url("../fonts/overpass/Overpass-Regular.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Overpass";
  src: url("../fonts/overpass/Overpass-Bold.ttf") format("truetype");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Overpass";
  src: url("../fonts/overpass/Overpass-ExtraBold.ttf") format("truetype");
  font-display: swap;
  font-weight: 900;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li {
  font-family: "Saira Condensed";
}

.blue-text {
  color: $brand-blue;
}

.light-gray-text {
  color: $light-gray;
}

.primary-text {
  color: $brand-primary;
}

.wide-text {
  letter-spacing: 2px;
}

.jumbo-text {
  font-size: 56px;
}

p,
ol li {
  line-height: 1.65;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  font-family: "Overpass", sans-serif;
}

.eyebrow:after {
  content: "";
  height: 4px;
  width: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: $brand-primary;
}

.mini-text {
  font-size: 15px;
  text-transform: uppercase;

  a {
    color: #fff !important;

    &:hover {
      text-decoration: underline !important;
    }
  }
}

.text-sz-normal {
  line-height: 1.65;
  font-size: 18px;
}
