.mt-lg-61 {
  @media screen and (min-width: 992px) {
    margin-top: 61px;
  }
}

.pt-lg-100 {
  @media screen and (min-width: 992px) {
    padding-top: 100px !important;
  }
}

.pb-lg-100 {
  @media screen and (min-width: 992px) {
    padding-bottom: 100px !important;
  }
}

.pb-lg-200 {
  @media screen and (min-width: 992px) {
    padding-bottom: 200px;
  }
}