.homepage-hero {
  background: url('../images/hero-background.jpg') top center no-repeat;
  background-size: cover;
  // min-height: -webkit-fill-available;
  // height: 100vh;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom right, #030e1e, #031530);
    opacity: 0.7;
  }

  h1 {
    margin-bottom: 15px;
    font-size: 70px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1em;
    color: #FFF;

    @media screen and (min-width: 768px) {
      font-size: 90px;
    }
  }

  h4 {
    margin-bottom: 0px;
    font-size: 25px;
    font-weight: 500;
    text-transform: uppercase;
    color: #FFF;
    line-height: 1.4;
  }

  h3 {
    margin-bottom: 30px;
    color: $brand-primary;
    font-size: 35px;
    font-weight: 500;
    text-transform: uppercase;
  }
}
