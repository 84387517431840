.btn-outline {
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: $brand-primary;
  border-radius: 0px;

  &:hover, &:focus {
    color: #FFF;
    background: $brand-primary;
  }
}

.btn-hero {
  font-family: "Saira Condensed", Sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  padding: 19px 25px 19px 25px;
  color: #ffffff;
  background-color: rgba(20,20,20,0);
}

.mini-btn-outline {
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: $brand-primary;
  border-radius: 0px;
  font-family: "Saira Condensed", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  padding: 10px 25px 10px 25px;
  color: #ffffff;
  background-color: rgba(20,20,20,0);

  &:hover, &:focus {
    color: #FFF;
    background: $brand-primary;
  }
}

.btn-black {
  font-family: "Saira Condensed", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  background-color: #191c1e;
  border-radius: 0px 0px 0px 0px;
  padding: 20px 30px 20px 30px;
  color: #fff;
  transition: 0.3s;

  &:hover {
    color: #000;
    background-color: $brand-primary;

    svg {
      fill: #000
    }
  }

  svg {
    fill: #fff;
    transition: 0.3s;
  }
}

.btn-mini-black {
  font-family: "Saira Condensed", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  background-color: #191c1e;
  border-radius: 0px 0px 0px 0px;
  padding: 20px 30px 20px 30px;
  color: #fff;
  transition: 0.3s;
  border: 0px;

  &:hover {
    color: #FFF;
    background-color: darken(#191c1e, 10%);

    svg {
      fill: #000
    }
  }

  svg {
    fill: #fff;
    transition: 0.3s;
  }
}

.hire-btn {
  background: $brand-primary;
  text-align: center;
}

.small-btn {
  padding: 10px 20px;
}