.light-card {
  background: #fff;
  box-shadow: 2px 2px 15px 0px rgba(0,0,0,0.08);
  position: relative;

  .description-text {
    position: absolute;
    bottom: 20px;
    left: 30px;
    color: #fff;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    left: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    padding: 30px;
    display: flex;
    align-items: flex-end;
    height: 150px;
    font-weight: 500;
    padding-bottom: 20px;

    @media screen and (min-width: 992px) {
      height: 200px;
    }
  }
}

.normal-card {
  .image-shroud, .project-title {
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
  }

  .project-title {
    opacity: 1;
    position: absolute;
    bottom: 15px;
    left: 5px;
    z-index: 101;

    @media screen and (min-width: 768px) {
      opacity: 0;
    }

    h4 {
      padding: 5px 8px;
      background: #000;
      font-weight: 600;
      margin-bottom: 0px;
    }

    h5 {
      margin-top: 10px;
      margin-left: 7px;
      font-weight: 600;
      display: inline-block;
      margin-right: 10px;
    }

    .icon-right {
      height: 25px;
      margin-left: -7px;
      margin-top: -3px;

      path {
        fill: #FFF;
      }
    }
  }

  &:hover {
    cursor: pointer;

    .image-shroud {
      position: absolute;
      background: rgba(0, 0, 0, 0.3);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .project-title {
      opacity: 1;
      left: 15px;
    }
  }
}