.carousel-indicators li {
 background-color: $brand-primary;
}

.slide-info {
  background: #000;
  padding: 50px 50px;
  max-width: 400px;
  left: 100px;
  background: #000;
  padding: 20px 50px;
  max-width: 100%;
  margin: 0px 0 60px;


  @media screen and (min-width: 992px) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 100px;
    max-width: 400px;
  }

  h2, p {
    color: #fff;
  }

  h2 {
    font-weight: 600;

    @media screen and (min-width: 992px) {
      font-size: 50px;
    }
  }

  @media screen and (min-width: 992px) {
    max-width: 500px;
  }
}

.carousel-control-prev, .carousel-control-next {
  width: 10%;

  @media screen and (min-width: 992px) {
    width: 5%;
  }
}