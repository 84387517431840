@import "bootstrap/bootstrap";

@import "config/colors";
@import "config/typography";

@import "components/spacing";
@import "components/header";
@import "components/heros";
@import "components/backgrounds";
@import "components/btns";
@import "components/cards";
@import "components/modals";
@import "components/loader";
@import "components/carousel";
@import "components/contact-us";
@import "components/forms";
@import "components/footer";

@import "miscellaneous";
