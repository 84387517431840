.navbar-dark {
  background-color: rgba(40,40,40,  0.96);

  .hire-button {
    display: flex;
    align-items: center;
    background: $brand-primary-bright;
    transition: 0.3s;

    .icon-right {
      height: 25px;
      margin-left: -7px;
      margin-top: -3px;

      path {
        fill: #FFF;
      }
    }

    li a {
      font-weight: 600;
      color: #ffffff;
      text-transform: uppercase;
      padding: 0px;
      letter-spacing: 0.02em;
      background-color: rgba(69,77,89, 0.91);
      font-size: 17px;
      text-shadow: 1px 1px 8px rgba(0,0,0, 0.25);
      background: $brand-primary-bright;
      line-height: 1;
      transition: 0.3s;
      font-size: 18px;

      @media screen and (min-width: 1022px) {
        padding: 20px;
      }
    }

    &:hover, &:focus {
      background: $brand-primary;

      li {
        a {
          background: $brand-primary;
          text-decoration: none;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    background-color: rgba(69,77,89, 0.91);

    .hire-button li {
      padding: 40px 36px 40px 36px;
    }
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: #FFF;
  font-size: 18px;

  &:hover, &:focus {
    color: $brand-primary;
  }
}

.navbar-dark .navbar-nav .active > .nav-link {
  color: $brand-primary;
}

.navbar-brand img {
  max-width: 150px;
}

.top-nav {
  background: #1d1f22;
  color: rgba(255,255,255, 0.75);

  .social-nav svg {
    height: 33px;

    path {
      fill: #bbb;
    }
  }

  .muted-nav-link:hover, .muted-nav-link:focus {
    path {
      fill: #fff;
    }
  }
}

.muted-nav-link {
  color: #bbb;

  svg {
    margin-top: -4px;

    .email-contact-svg, .phone-contact-svg path {
      fill: #bbb;
    }
  }

  &:hover, &:focus {
    color: #fff;
    text-decoration: none;

    svg {
      .email-contact-svg, .phone-contact-svg path, .twitter-svg path  {
        fill: #fff;
      }
    }
  }
}
