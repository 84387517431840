.footer {
  background: url('/assets/images/footer-background.jpg');
  background-size: cover;
  background-position: center;

  .social-nav svg {
    height: 47px;

    @media screen and (min-width: 768px) {
      height: 33px;
    }

    path {
      fill: #bbb;
    }

    &:hover {
      path {
        fill: #fff;
      }
    }
  }

  a svg {
    path {
      fill: $light-gray;
    }
  }

  a {
    color: #aaa;

    &.primary-text {
      color: $brand-primary;
    }
  }

  a:hover, a:focus {
    color: #fff;
    text-decoration: none;

    svg {
      path {
        fill: #fff;
      }
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0px;
  }
}