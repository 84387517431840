.dark-blue-construction-background {
  background: url('../images/backgrounds/construction-background.jpg') center center no-repeat;
  background-size: cover;
  color: #FFF;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(180deg, #000e28 0%, #000e28 100%);
    opacity: 0.94;
  }
}

.white-contstruction-background {
  background: url('../images/backgrounds/construction-background-2.jpg') center center no-repeat;
  background-size: cover;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
    opacity: 0.84;
  }
}
