.modal-lg {
  @media screen and (min-width: 992px) {
    max-width: 1000px;
  }
}

#project-modal {
  .modal-content, .modal-header {
    background: transparent;
    border: 0px;
  }

  .modal-image-container {
    margin-top: -22px;
  }

  .close {
    background: #fff;
    opacity: 1;
    z-index: 999;
    padding: 10px 15px;
    border-radius: 23px;
    margin-right: -38px;
  }
}